function setDatasetSlug(slug) {
  document.cookie =
    window.__DATASET_SLUG_COOKIE + '=' + slug + ' ;path=/'
}

document.addEventListener('DOMContentLoaded', function () {
  setDatasetSlug(window.__CURRENT_DATASET_SLUG)
});

document.addEventListener('click', function (event) {

	// If the clicked element doesn't have the right selector, bail
	if (!event.target.matches('[data-datasetslug]')) return;

	// Don't follow the link
	event.preventDefault();

  setDatasetSlug(event.target.getAttribute("data-datasetslug"));

  window.location = '/'

});