(function(exports) {
  'use strict';

  /**
   * Sets up much of the functionality necessary to create the beeswarm plot. Many parameters (line, xBasis, xPadding,
   * maxPointsPerLine, color) are used to determine the characteristics of the beeswarm plot.
   * @param line takes the points that will be manipulated into a beeswarm plot
   * @param xBasis takes the basis for x
   * @param xPadding takes the desired amount of padding between each point regarding x-coordinates
   * @param maxPointsPerLine takes the maximum amount of points allowed per line
   * @param color takes any number of colors used for coloring data points on the beeswarm plots
   * @returns {Array} an array of beeswarm points with x,y coordinates are returned
   */
  function prepareLine(line, xBasis, xPadding, maxPointsPerLine, color) {
    var result = [];
    var x, i, len;
    for (var start = 0; start < line.length; start += maxPointsPerLine) {
      x = 0;
      len = Math.min(maxPointsPerLine, line.length - start);
      for (i = 0; i < len; i++) {
        x = x > 0 ? x - i : x + i;
        result.push({
          x: x * xPadding + xBasis,
          y: line[start + i],
          color: color
        });
      }
    }
    return result;
  }

  exports.beeSwarmData = function beeSwarmDataVarroa(series, yPadding, xPadding, maxPointsPerLine, colors) {
    return series.reduce(function (result, data, x) {
      return result.concat(beeSwarm(data, x, xPadding, yPadding, maxPointsPerLine, colors[x % colors.length]));
    }, []);
  }

  /**
   * Calls prepareLine function to process and return all data necessary for beeswarm plots in highcharts.
   * @param series takes a 2d array of data to turn into beeswarm plot x,y coordinates
   * @param xBasis takes the basis for x
   * @param xPadding takes the desired amount of padding between each point regarding x-coordinates
   * @param yPadding takes the desired amount of padding between each point regarding y-coordinates
   * @param maxPointsPerLine takes the maximum amount of points allowed per line
   * @param color takes any number of colors used for coloring data points on the beeswarm plots
   * @returns {*[]} a variable length array of all beeswarm x,y coordinates necessary for creating a highchart.
   */
  var beeSwarm = function beeSwarm(series, xBasis, xPadding, yPadding, maxPointsPerLine, color) {
    series.sort();
    var p;
    var line = [];
    var swarm = [];
    var y = series[0] + yPadding;
    for (var i = 0; i < series.length; i++) {
      p = series[i];
      if (p > y) {
        swarm = swarm.concat(prepareLine(line, xBasis, xPadding, maxPointsPerLine, color));
        y = p + yPadding;
        line = [];
      }
      line.push(p);
    }
    return swarm.concat(prepareLine(line, xBasis, xPadding, maxPointsPerLine, color));
  }

  /**
   * Calculates y units to pass into beeSwarm yPadding argument.
   * @param yMin takes the minimum y value
   * @param yMax takes the maximum y value
   * @param pxMin takes the minimum pixel value
   * @param pxMax takes the maximum pixel value
   * @param pointRadius takes the point radius
   */
  exports.calculateYPadding = function calculateYPadding(yMin, yMax, pxMin, pxMax, pointDiameter) {
    var yUnits = (pointDiameter - pxMin) * ((yMax - yMin)/(pxMax - pxMin)) + yMin;
    return yUnits;
  }

  exports.parseTimeSeriesXDates = function parseAllDates(twoDArray) {
    for (var i = 0; i < twoDArray.length; i++) {
      twoDArray[i][0] = Date.parse(twoDArray[i][0])
    }
    return twoDArray
  }

})(window.ReportTools = {});
